<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Current Available Promoter</p>
                <p class="text-title mb-0">You don't have permission to view Promoter Pool</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 d-flex justify-space-between">
                    <h2 class="font-weight-regular">Current Available Promoter</h2>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-dialog persistent width="320px" ref="startDialog" :return-value.sync="filters.start_date" v-model="startDateDialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field readonly dense hide-details label="Start Date" v-on="on" v-bind="attrs" v-model="filters.start_date"></v-text-field>
                        </template>
                        <v-date-picker scrollable v-model="filters.start_date">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="startDateDialog=false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.startDialog.save(filters.start_date)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-dialog persistent width="320px" ref="endDialog" :return-value.sync="filters.end_date" v-model="endDateDialog">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field readonly dense hide-details label="End Date" v-on="on" v-bind="attrs" v-model="filters.end_date"></v-text-field>
                        </template>
                        <v-date-picker scrollable v-model="filters.end_date">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="endDateDialog=false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.endDialog.save(filters.end_date)">OK</v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col class="pt-1" cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <v-select dense label="Months*" class="my-3" :items="months" :menu-props="{top: false, offsetY: true}" v-model="filters.month"></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" >
                    <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
                        <v-icon small left>mdi-magnify</v-icon> Search
                    </v-btn>
                    <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                        <v-icon small left>mdi-undo</v-icon> Reset
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="items.length === 0">
                    <p class="mb-0">No Available Promoter</p>
                </v-col>
                 <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="loading===true">
                    <p class="mb-0">Loading Promoter......</p>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="items.length > 0">
                    <v-sheet color="white" elevation="0">
                        <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                            <template v-slot:item='{ item, index }'>
                                <tr>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="index + 1"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.name"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.gender"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.race"></td> 
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.age"></td>  
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.area_of_residence"></td>
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.is_weekend"></td> 
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center px-0" v-text="item.last_project"></td>                
                                    <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                        <v-menu bottom offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn small icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-horizontal</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list dense>
                                                <v-list-item @click="chatWithPromoter(item)">
                                                    <v-list-item-title>Chat with promoter</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
    </div>
</template>


<script>
import { getLocalStorage } from '@/utils/auth'
export default {
    name: 'PromoterPool',
    data() {
        return {
            tab: null,
            loading: true,
            reportDateModal: null,
            startDateDialog: false,
			endDateDialog: false,
            filters: {
				start_date: '',
				end_date: '',
                month: '',
                page: 1
			},
             auth: {
                full_name: ''
            },
             months: [
                { text: '1 Month', value: '1' },
                { text: '2 Month', value: '2' },
                { text: '3 Month', value: '3' },
                { text: '12 Month', value: '12' },
            ],
            permissions: {
                can_list: false,
                can_edit: false
            },
			selectedIndex: -1,
            object: {},
            projects: [],
            items: [],
            headers: [],
            pagination: {},
        }
    },
    mounted: function() {
        this.getAuthObject()
        this.get()
    },
    methods: {
        get: function() {
            this.items = []
            var filters={}
            if (this.filters.start_date!='' && this.filters.end_date!='') {
                filters = {start_date: this.filters.start_date, end_date: this.filters.end_date, month: ''}
            }
            else if(this.filters.month!='') {
                filters = {start_date: '', end_date: '', month: this.filters.month}
            }
            else {
                filters = {start_date: '', end_date:'', month: ''}
            }
            this.$store.dispatch('epanel/dashboard/getDashboardPromoterList', filters).then((response) => {
                this.loading = true
                this.items = response.data.items
                this.headers = response.data.headers
                this.pagination = response.data.pagination
                this.permissions = response.data.permissions
                console.log(this.permissions)
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        chatWithPromoter: function(item) {
            let text = "Hi, "+ item.name + " I am " + this.auth.full_name + " from Elitez (FMCG), you last worked with us on " + item.last_project_work_date 
                        + " at " + item.last_working_location + " for a project called " + item.last_project 
                        + ". I wish to check if you ara available to work with us on " + this.filters.start_date + " to " + this.filters.end_date
                        + ". Do let me know if you are available and keen to work!"
            let url = "https://api.whatsapp.com/send?phone="+item.phone_number+"&text="+text
            window.open(url, '_blank');
        },
        search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
        reset: function() {
            this.filters = {start_date: '', end_date: '', month: '',  page: 1 }
            this.get()
        },
        getAuthObject: function(){
            if(this.$store.getters.auth.full_name){
                this.auth = this.$store.getters.auth
            }else{
                let user = getLocalStorage('blink-data', 'user')
                if(user){
                    this.auth = user
                }
            }
        },
    },
}
</script>


<style scoped>

/deep/ .v-toolbar__content {
    padding: 4px 0px !important;
}

</style>